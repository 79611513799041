/**
* CapVerif popin js file
* Modified for use in SMCP
*
*/

var capverif = capverif || {};

capverif.propositionChoisie = {};

// Max times capVerif can overwrite the address entered by the user before allowing submission anyway
capverif.maxRetries = app.constants.CAPVERIF_MAXREWRITE;

capverif.step = {
	// Uniquement pour la vérification
	UNDEFINED: 'Undefined',
	// Permet de valider l'adresse
	VALIDATE: 'ValidateForm',
	// Dernière étape de la recherche, on ne pourra pas avoir de meilleure adresse
	FINAL: 'FinalAddress'
};

/**
 * Objet contenant la valeur correspondant à la position du numéro de voie
 * 1: Avant la voie
 * 2: Après la voie
 * @type {object}
 */
capverif.streetNumberPosition = {
	BEFORE: 1,
	AFTER: 2
};


// capverif popin messages
capverif.text = {
	confirm_question :			app.capverif.confirm_question ,
	modify :					app.capverif.modify ,
	valid :						app.capverif.valid ,
	modification_required :		app.capverif.modification_required ,
	keep :						app.capverif.keep ,
	intro :						app.capverif.intro ,
	address_not_found :			app.capverif.address_not_found ,
	no_match :					app.capverif.no_match ,
	multiple_matches :			app.capverif.multiple_matches ,
	modify_your_address :		app.capverif.modify_your_address ,
	select_an_address :			app.capverif.select_an_address ,
	entered_address :			app.capverif.entered_address ,
	matches_gt_1 :				app.capverif.matches_gt_1 ,
	matches_eq_1 :				app.capverif.matches_eq_1 ,
	error_streetnum_length :	app.capverif.error_streetnum_length ,
	error_streetcomp_length :	app.capverif.error_streetcomp_length,
	wrong_cp_or_loc :			app.capverif.wrong_cp_or_loc
};

// Global object to hold the address data and access it from anywhere
capverif.addressData = new Object();

/**
* Custom initialization for integration on SMCP brands
*
*/
if(app.constants.CAPVERIF_ENABLED) {
	$(document).ready(function() {

		// Create the container for the capVerif popup
		if(!$('#cap-popup-form-adresse').length) {
			$('body').append($("<div id='cap-popup-form-adresse'></div>"));
		}

		// Create the submit handler to make the checks before the user submits his shipping form
		$('form.checkout-shipping').on('submit', function(e, submitable){

			// If we submitted through the address choice in the capVerif popin
			if(submitable) {
				// Simulate the presence of the "finalize" button in the submitted form
				// Otherwise, backend will ignore the submission and reload the form,
				// because it doesn't go through the "save" transition in the COShipping-Start pipeline.
				var buttonName = $(e.target).find("button[name$='_save']").attr("name");
				$(e.target).append($("<input type='hidden' name='"+buttonName+"' value='OK'/>"));

				return true;
			}

			var isAddressSelected = $("input[name=addressbook]:checked").length;
			var isFormValid = $('form.checkout-shipping.address').valid();
			capverif.isCheckCPCountry = ($('.checkout-shipping select.capSaisieFieldCountry').val() == "FR");

			if(!isAddressSelected) {
				// If no address is selected, it means we may need to check the form data directly (if autocomplete on the postal code/city fields is not used)
				capverif.addressData = new Object();
				capverif.addressData.address1		= $('.checkout-shipping input.streetNb').val() + ' ' + $('.checkout-shipping .capSaisieFieldVoie').val();
				capverif.addressData.postalCode		= $('.checkout-shipping .capSaisieFieldCPLoc').val();
				capverif.addressData.city			= $('.checkout-shipping .capSaisieFieldCity').val();
				capverif.addressData.countryCode	= $('.checkout-shipping .capSaisieFieldCountry').val();
				capverif.addressData.qualityCode	= $('.checkout-shipping .capSaisieFieldQualityCode').val();
			} else {
				// else we can get the saved data from the hidden select listing the saved addresses.
				var slctAddressID = $('input[name$=selectedAddressID]').val();
				var addressOption = $("option[value='"+slctAddressID+"']");
				var checkRslt = addressOption.data("check-result");
				capverif.addressData = addressOption.data("address");
			}

			/* we only do the test if:
			* - the form is valid
			* - the qualityCode is non-existent or different from 00
			* - either, a saved address is selected OR no address is selected and the current country is a country for which capVerif is active
			*/
			if( (!capverif.addressData.qualityCode || capverif.addressData.qualityCode != "00") && isFormValid && ((isAddressSelected && slctAddressID) || (!isAddressSelected && capverif.isCheckCPCountry)) )
			{
				e.preventDefault(); e.stopPropagation();

				// If we already checked this address once (only used for saved addresses check)
				if(checkRslt){
					// If the address returned a qualityCode of 20 or
					// if the address returned a qualityCode of 10, recall previous data
					capverif.ouvrirPopup(checkRslt);
				} else {
					// If the adress has not been tested yet, do ajax call
					$.ajax(app.urls.verifyAddress,
						{
							type: "POST",
							data: capverif.addressData,
							dataType:"json",
							success: function(data, status, jqXhr){
								var checkResult = capverif.ouvrirPopup(data);
								// 1: the address returned a qualityCode 10, so save the result data in the element in case we need it later
								// 2: the address returned a qualityCode 20, so the user has to modify it by hand
								// If we don't have addressOption (not checking a saved address), we can't save the result of the call. It will be made every time.
								if(addressOption && checkResult != 0){
									addressOption.attr("data-check-result", JSON.stringify(data));
								}
							}
						}
					);
				}
			}
		});
	});
}

/**
 * Permet d'ouvrir la popup des propositions
 * Si une seule proposition valide et/ou corrigée, on valide directement la proposition
 * Si une seule proposition invalide, on demande confirmation
 * Si plusieurs propositions, on affiche la liste des propositions
 *
 * @param  {object} response Réponse de l'appel du service web
 * @return {void}
 */
capverif.ouvrirPopup = function( response )
{
	response = response || {};

	if(response.error) {
		// If an error occured, do nothing and proceed with the submit
		this.forceAddress("");
		return;
	}

	var _this = this;
	// Adresse saisie par l'utilisateur
	var adresse_saisie = '';
	// Adresse(s) renvoyée(s) par le service
	var adresses = {};
	// Premier index du tableau de réponse
	var first_index = 'addresses';
	// Deuxième index du tableau de réponse
	var second_index = 'address';
	// Code retour du service
	var code_retour = '';
	// Sert à afficher la proposition à l'utilisateur
	var display_address = '';
	// La liste des numéros présents dans les propositions
	var list_street_number = [];
	// Tableau permettant d'ajouter tous les numéros les un à la suite des autres
	var list_street_number_buffer = [];
	// Numéro de voie de chaque proposition
	var numero_voie = '';
	// (B pour Bis, T pour Ter, etc.)
	var extension_numero_voie = '';
	// Variables pour la popup
	var popup_content = '';
	var popup_form_adresse = '';
	var options_popup = {
		closeText: '',
		resizable: false,
		autoOpen: false,
		height: 'auto',
		width: 650,
		modal: true,
		draggable: false,
		dialogClass: "CapVerifPopin",
		open: function()
		{
			var $popup = $(this);
			$('.ui-widget-overlay').on('click', function() {
				$popup.dialog('close');
			});
			$('.cap-popup__result-item').on('click', function(event) {
				$li = $(this);
				$('.cap-popup__radio').prop('checked', false);
				$li.find('.cap-popup__radio').prop('checked', true);
				$('.cap-popup__result-item').removeClass('cap-popup__result-item__selected');
				$li.addClass('cap-popup__result-item__selected');
			});
		}
	};
	var j = 0;

	if (response[first_index] && response[first_index][second_index] && response[first_index][second_index].length)
	{
		adresses = response[first_index][second_index];
	}
	else if(response[first_index] && response[first_index][second_index])
	{
		adresses = [];
		adresses.push(response[first_index][second_index]);
	}

	// On reconstruit l'adresse saisie, sur une seule ligne
	adresse_saisie = _this.getInputAddress();

	if (adresses[0] && adresses[0].extendedQualityCode)
	{
		code_retour = adresses[0].extendedQualityCode;
	}
	else if(adresses.extendedQualityCode)
	{
		code_retour = adresses.extendedQualityCode;
	}

	// The 10th character in the extended qc encodes the treatment/result for the CP and loc.
	// Depending on the value it means that one of them was wrong/corrected according to capverif.
	// For now, this check is limited to the FR locale, hence the first part of the test
	var isWrongCPorLoc = _this.isCheckCPCountry && (app.constants.CAPVERIF_POSTCODEFAIL.indexOf(code_retour.charAt(9)) > -1);

	// Si l'adresse est litigieuse, on affiche la popup
	if (_this.isAddressLitigious(code_retour))
	{
		// Construction de la popup
		popup_content += _this.construitPopupHeader({recapAdresse: adresse_saisie, nbAdresses: adresses.length, isWrongCPorLoc: isWrongCPorLoc});

		// Affichage des propositions
		$.each(adresses, function(index, adresse) {

			display_address = adresse.inputOutput || '';

			// Récupération des différents numéros
			if (adresse.listStreetNumber)
			{
				list_street_number = adresse.listStreetNumber.split(';');
			}
			else if (adresse.streetNumber)
			{
				numero_voie = adresse.streetNumber;

				extension_numero_voie = '';

				if (adresse.streetNumberExt)
				{
					extension_numero_voie = adresse.streetNumberExt;
				}

				if (numero_voie.length < 4 && extension_numero_voie !== '')
				{
					numero_voie += ' ';
				}

				numero_voie += extension_numero_voie;

				list_street_number = numero_voie;
			}
			var streetNumberDisplay = '';
			popup_content += '' +
				'  <li class="cap-popup__result-item">' +
				"    <input type='hidden' value='"+JSON.stringify(adresse)+"' name='propChoisie[]'>";

			// Si la variable est de type string, cela veut dire qu'il n'y a qu'un seul numéro
			// Si la taille de la variable vaut zéro, cela veut dire qu'il n'y a pas de numéro
			if (typeof list_street_number === 'string' || list_street_number.length === 0)
			{
				streetNumberDisplay = '<span class="cap-popup__input-input" id="streetNumber'+j+'">'+list_street_number+'</span>';
				popup_content += '' +
				'    <div class="input">' 
			}
			else
			{
				popup_content += '' +
				'    <input type="hidden" value="'+list_street_number+'" name="list_street_number'+j+'">' +
				'    <div class="input select-mode">';

				list_street_number_buffer.push('<select id="ListeBox'+j+'" class="cap-popup__input-select" name="streetNumber'+j+'">');

				list_street_number_buffer.push('<option value=""> </option>');

				$.each(list_street_number, function(index, value) {

					if (value !== '')
					{
						list_street_number_buffer.push('<option value="'+value+'">'+value+'</option>');
					}
				});

				list_street_number_buffer.push('</select>');
				popup_content += list_street_number_buffer.join('\n');

				// On remet le buffer à 0
				list_street_number_buffer = [];
			}

			popup_content += '' +
			'          <input type="radio" name="cap-popup-adresse_rbt" class="cap-popup__radio" id="rbt-address-'+j+'" value="'+j+'">' +
			'          <label for="rbt-address-'+j+'" class="radio-label">' + streetNumberDisplay+ ' ' + display_address + '</label>' +
			'        </div>' +
			'      </li>';

			j++;
		});

		popup_content += '' +
			'    </ul>' +
			'  </div>' +
			'</form>';

		$('#cap-popup-form-adresse').html(popup_content);
		options_popup.title = _this.text.confirm_question;
		options_popup.buttons = [
			{
				text: _this.text.modify,
				class: 'cap-popup__button',
				click: function() {

					// Fire a click event on the modify link of the selected address
					var aid = $('input[name$=selectedAddressID]').val();
					$("input[id='"+aid+"']").parent().find("a.address-edit").click();

					$(this).dialog('close');
				}
			},
			{
				text: _this.text.valid,
				class: 'cap-popup__button-confirm',
				disabled: isWrongCPorLoc,
				click: function() {
					_this.checkAdresseSelected();
				}
			}
		];

		popup_form_adresse = $('#cap-popup-form-adresse').dialog(options_popup);
		popup_form_adresse.dialog('open');

		// Only add extra events if we want to disable the possibility for a user to force the input address
		function enableValidation(event) {
			var radioId = $(event.target).parent().find('input.cap-popup__radio').val();
			var selectId = $(event.target).parent().find('select.cap-popup__input-select');
			// If we did not select the input address and the street number select, if present, has a correct value
			if(radioId != -99 && ( (selectId.length > 0 && selectId.val()) || selectId.length == 0)) {
				// Enable chosen capVerif address validation
				$('.cap-popup__button-confirm').button("enable");
			} else {
				$('.cap-popup__button-confirm').button("disable");
			}
		}
		if(isWrongCPorLoc) {
			// Event on the label click, and the select change if a street number should be selected
			$(document).on('click', '.cap-popup__radio + label', enableValidation);
			$(document).on('change', '.cap-popup__input-select', enableValidation);
		} else {
			// Remove the events, otherwise they still apply if the two cases happen on the same page
			$(document).off('click', '.cap-popup__radio + label', enableValidation);
			$(document).off('change', '.cap-popup__input-select', enableValidation);
		}

		$(window).resize(function() {
			popup_form_adresse.dialog('option', 'position', {my: 'center', at: 'center', of: window});
		});

		return 1;
	}
	// Si l'adresse est valide, on valide tout
	else if (_this.isAddressValid(code_retour))
	{
		// Maybe update the form with correctly formated data from capVerif
		_this.fillAddressForm(adresses[0]);
		// Otherwise, we have to at least put the "OK" quality code in the form to save it
		//$("form.address").find("input[id$='_qualityCode']").val("00");

		// Here we pass extraData to the submit trigger, so we can make the handler function know that
		// the adress is OK and that it should proceed with a normal submit.
		$('form.checkout-shipping').trigger("submit", true);

		return 0;
	}
	// L'adresse est rejetée, on affiche une popup de confirmation
	else if (_this.isAddressInvalid(code_retour))
	{
		popup_content += _this.construitPopupHeader({recapAdresse: adresse_saisie, nbAdresses: adresses.length, error: true, isWrongCPorLoc: isWrongCPorLoc});

		$('#cap-popup-form-adresse').html(popup_content);
		options_popup.title = _this.text.modification_required;
		options_popup.buttons = [
			{
				text: _this.text.modify,
				class: 'cap-popup__button',
				click: function()
				{
					// Fire a click event on the modify link of the selected address
					var aid = $('input[name$=selectedAddressID]').val();
					$("input[id='"+aid+"']").parent().find("a.address-edit").click();

					$(this).dialog('close');
				}
			},
			{
				text: _this.text.keep,
				class: 'cap-popup__button-confirm',
				disabled: isWrongCPorLoc,
				click: function()
				{
					// The user chose to force the current address instead of modifying it
					_this.forceAddress("42");
				}
			}
		];

		popup_form_adresse = $('#cap-popup-form-adresse').dialog(options_popup);
		popup_form_adresse.dialog('open');

		$(window).resize(function() {
			popup_form_adresse.dialog('option', 'position', {my: 'center', at: 'center', of: window});
		});

		return 2;
	}
};

/**
 * Permet de récupérer l'adresse saisie sur une seule ligne
 *
 * @param  {string}  separateur Séparateur que l'on veut mettre entre chaque élément de l'adresse
 * @return {string}             Adresse sur une seule ligne séparée par le séparateur (par défaut un '-')
 */
capverif.getInputAddress = function( separateur )
{
	var _this = this;
	var ligne4 = '';
	var ligne6 = '';
	var user_input_address = '';
	var sep = ' - ';

	if (separateur && $.trim(separateur) !== '')
	{
		sep = ' ' + separator + ' ';
	}

	user_input_address += capverif.addressData.address1 + sep + capverif.addressData.postalCode + sep + capverif.addressData.city + sep + capverif.addressData.countryCode;

	return user_input_address;
};

/**
 * Permet de construire le haut de la popup
 *
 * @param  {object}  options              Options de la fonction
 * @param  {string}  options.recapAdresse Adresse saisie par l'utilisateur
 * @param  {integer} options.nbAdresses   Nombre d'adresses qui ont été retrouvées
 * @param  {boolean} options.error        Pour savoir si l'adresse est valide ou non
 *                                        Par défaut, considéré à false si inexistant
 * @return {string}                       Code HTML de la popup
 */
capverif.construitPopupHeader = function( options )
{
	options = options || {};

	var _this = this;
	var content = '';
	var error = (typeof options.error === 'boolean') ? options.error : false;
	var nb_adresses = parseInt(options.nbAdresses) || 0;
	var display_match_sentence = _this.construitPhraseListe(nb_adresses);
	var recap_adresse = options.recapAdresse || '';
	// Pour savoir si on met une taille auto ou une taille fixe pour la popup
	var popup_style = '';

	if (nb_adresses > 4)
	{
		popup_style = ' style="height: 280px;"';
	}

	content += '' +
		'<form action="" method="post" onsubmit="return false">' +
		'  <div id="cap-popup__header">' +
		'    <p class="cap-popup__header-title">'+_this.text.intro+'</p>';

	if (error)
	{
		content += '    <p class="next__intro-text">'+_this.text.address_not_found+'</p>';
		display_match_sentence = _this.text.no_match;
	}
	else
	{
		content += '    <p class="next__intro-text">'+_this.text.multiple_matches+'</p>';
	}

	content += '' +
		'    <ul>' +
		'      <li class="cap-popup__header-item">'+_this.text.modify_your_address+'</li>';

	if (!error)
	{
		content += '      <li class="cap-popup__header-item">'+_this.text.select_an_address+'</li>';
	}

	content += '' +
		'    </ul>' +
		'  </div>' +

		'  <div class="cap-popup__user-input">' +
		'    <h6 class="cap-popup__title">'+_this.text.entered_address+'</h6>' +
		'    <ul class="cap-popup__user-input-adresse">' +
		'      <li class="cap-popup__result-item"><input type="radio" name="cap-popup-adresse_rbt" class="cap-popup__radio" id="rbt-adresse-99" value="-99" checked><label class="cap-popup-prop__adresse radio-label">' + recap_adresse + '</label>' +
		'      </li>' +
		'    </ul>' +
		'    <div class="clear-both"></div>';

	if(options.isWrongCPorLoc) {
		content += '' +
		'    <p>' + _this.text.wrong_cp_or_loc + '</p>';
	}

	content += ''+
		'    <p>' + _this.text.multiple_matches + '</p>'+
		'  </div>' +

		'  <div class="cap-popup__result" data-textbutton="Valider l\'adresse sélectionnée"'+popup_style+'>' +
		'    <h6 class="cap-popup__title">' + display_match_sentence + '</h6>' +
		'    <ul>';

	return content;
};

/**
 * Permet de construire la phrase récapitulant le nombre de correspondances trouvées
 *
 * @param  {integer} nbAdresses Nombre d'adresses retrouvées
 * @return {string}             Phrase du genre "X correspondances ont été trouvées"
 */
capverif.construitPhraseListe = function( nbAdresses )
{
	var _this = this;
	var display_match_sentence = parseInt(nbAdresses) || 0;

	if (display_match_sentence > 1)
	{
		display_match_sentence += _this.text.matches_gt_1;
	}
	else if (display_match_sentence === 1)
	{
		display_match_sentence += _this.text.matches_eq_1;
	}
	else
	{
		display_match_sentence = _this.text.no_match;
	}

	return display_match_sentence;
};

/**
 * Permet de vérifier l'adresse sélectionnée dans la popup
 *
 * @return {void}
 */
capverif.checkAdresseSelected = function( adresse )
{
	adresse = adresse || {};

	var _this = capverif;
	var $dialog = $('.ui-dialog');
	var $dialog_titlebar = $dialog.find('.ui-dialog-titlebar');
	var $dialog_title = $dialog_titlebar.find('.ui-dialog-title');
	var $popup = $('#cap-popup-form-adresse');
	var $form = $popup.find('form');
	// On récupère la valeur du radio bouton qui a été choisi
	var address_index = parseInt($form.find('input[name=cap-popup-adresse_rbt]:checked').val());
	// Variable qui contiendra le numéro choisi dans la liste
	var list_street_number = '';
	// Variable qui contiendra l'input text du numéro de voie
	var number_input = '';
	// Variable qui contiendra le numéro
	var numero_voie = '';
	// Variable qui contiendra le select de la liste des numéros
	var select_input = '';
	// Variable qui contiendra la valeur du select
	var select_value = '';

	// On remet le step à Validate pour pouvoir valider l'adresse finale
	_this.propositionChoisie.step = _this.step.VALIDATE;

	if (address_index === -99)
	{
		// User chose to force the original address, even if some valid alternatives were given
		_this.forceAddress("42");
	}
	else
	{
		_this.propositionChoisie = JSON.parse($form.find('input[name^="propChoisie"]')[address_index].value);

		/**
		 * Si listStreetNumber existe, on le supprime car on n'en a plus besoin
		 * Ca permet de garder un objet JS 'propre'
		 */
		if (_this.propositionChoisie.listStreetNumber)
		{
			delete _this.propositionChoisie.listStreetNumber;
		}

		// On récupère le select qui contient la liste des numéros
		select_input = document.getElementById('ListeBox'+address_index);
		// On récupère l'input text qui contient le numéro de voie
		number_input = $form.find('#streetNumber'+address_index);

		// On a choisi un numéro dans une liste déroulante
		if (select_input !== null)
		{
			// On récupère la valeur du 'select'
			select_value = select_input.options[select_input.selectedIndex].value;

			// Si le numéro saisi est un nombre et qu'il dépasse 4 caractères alors le numéro n'est pas valide
			if (!isNaN(select_value) && select_value.length > 4)
			{
				// Ajout d'une classe d'erreur sur la popup des propositions (optionel)
				// $dialog_titlebar.removeClass('cap-popup-success').addClass('cap-popup-error');
				$dialog_title.html(_this.text.error_streetnum_length);
				return false;
			}
			// Si le numéro saisi n'est pas un nombre (il possède un complément) et qu'il dépasse 5 caractères alors le numéro n'est pas valide
			else if (isNaN(select_value) && select_value.length > 5)
			{
				// Ajout d'une classe d'erreur sur la popup des propositions (optionel)
				// $dialog_titlebar.removeClass('cap-popup-success').addClass('cap-popup-error');
				$dialog_title.html(_this.text.error_streetcomp_length);
				return false;
			}
			// Le numéro choisi est valide
			else
			{
				numero_voie = select_value.toUpperCase();
			}
		}
		else if (number_input)
		{
			numero_voie = number_input.text();
		}

		// Sauvegarde du numéro de voie
		_this.propositionChoisie.streetNumber = numero_voie;

		// Fill the form back with what CapVerif sent us
		_this.fillAddressForm(_this.propositionChoisie);

		// Here we pass extraData to the submit trigger, so we can make the handler function know that
		// the adress is OK and that it should proceed with a normal submit.
		$('form.checkout-shipping').trigger("submit", true);
	}
};

/**
 * Fill in the form fields with data returned from capVerif
 *
 * @param  {object}  address Object containing the address data
 */
capverif.fillAddressForm = function( address )
{
	if(capverif.maxRetries > 0) {
		capverif.maxRetries--;

		$checkoutForm = $("form.address .shipping-fields");
		$streetNumber = $checkoutForm.find("input[name$='_streetNb']");
		$address1 = $checkoutForm.find("input[name$='_address1']");
		$city = $checkoutForm.find("input[name$='_city']");
		$postalCode = $checkoutForm.find("input[name$='_zip']");
		$countryCode = $checkoutForm.find("select[id$='_country']");
		$qualityCode = $checkoutForm.find("input[id$='_qualityCode']");

		$streetNumber.val(address.streetNumber ? address.streetNumber : "");
		$address1.val(address.streetName);
		var wholeCity = address.locality + (address.localitySynonym ? " - "+address.localitySynonym : "");
		//if(wholeCity.length < 33) {
			$city.val(wholeCity);
		/*} else {
			$city.val(address.locality);
		}*/
		$postalCode.val(address.postalCode);
		$countryCode.val(address.countryCode);
		$qualityCode.val("00"); // Since we are using correct data sent back by CapVerif, we can assume a 00 qualityCode
	}
}

/**
* Force the termination of the process when the address is forced, and put the appropriate qualityCode in the field
*
* @param  {string}  qCode Quality code to fill in
*/
capverif.forceAddress = function( qCode ) {
	// To note the fact that the address was forced, even with some valid propositions,
	// put the qualityCode 42 in the field (as in the original CapVerif file)
	$("form.address").find("input[id$='_qualityCode']").val(qCode);
	// Submit the form directly without any other changes, or closing the popup
	$('form.checkout-shipping').trigger("submit", true);
}


/**
 * Permet de savoir si l'adresse est valide ou non
 *
 * @param  {string}  codeRetour Code retour de l'adresse
 * @return {boolean}            True si l'adresse est valide, false sinon
 */
capverif.isAddressValid = function( codeRetour )
{
	var valid_address = false;
	var return_code = codeRetour || '';
	// Récupération du premier caractère du code retour
	var return_code_first_char = parseInt(return_code.charAt(0));

	// Si le code retour vaut 0 ou 1, l'adresse est validée/corrigée
	if (return_code_first_char === 0 || return_code_first_char === 1)
	{
		valid_address = true;
	}

	return valid_address;
};

/**
 * Permet de savoir si l'adresse est litigieuse ou non
 * Il y a donc des propositions d'adresses
 *
 * @param  {string}  codeRetour Code retour de l'adresse
 * @return {boolean}            True si l'adresse est litigieuse, false sinon
 */
capverif.isAddressLitigious = function( codeRetour )
{
	var return_code = codeRetour || '';
	// Récupération du premier caractère du code retour
	var return_code_first_char = parseInt(return_code.charAt(0));
	// Récupération du troisième caractère du code retour
	var return_code_third_char = parseInt(return_code.charAt(2));
	var uncertain_address = false;

	/**
	 * Si le code retour à la position 0 est différent de 0 et différent de 1 et différent de 4, ça veut dire que l'adresse est incorrecte ou litigieuse
	 * Si le code retour à la position 2 est différent de X et différent de 0 et différent de 4 et différent de 6, ça veut dire que le numéro est invalide
	 * Donc on construit la popup
	 */
	if ((return_code_first_char !== 0 && return_code_first_char !== 1 && return_code_first_char !== 4) || (return_code.charAt(2) !== 'X' && return_code_third_char !== 0 && return_code_third_char !== 4 && return_code_third_char !== 6))
	{
		uncertain_address = true;
	}

	return uncertain_address;
};

/**
 * Permet de savoir si l'adresse est rejetée ou non
 *
 * @param  {string}  code_retour Code retour de l'adresse
 * @return {boolean}            True si l'adresse est rejetée, false sinon
 */
capverif.isAddressInvalid = function( codeRetour )
{
	var return_code = codeRetour || '';
	var wrong_address = false;

	// Le code retour vaut 4 donc l'adresse est rejetée
	if (parseInt(return_code.charAt(0)) === 4)
	{
		wrong_address = true;
	}

	return wrong_address;
};
