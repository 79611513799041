;(function ($) {
    $.smcpPhonePrefix = function (el, options) {
        options = $.extend( {
            'containerClass': 'phone-container',
            'prefix': [
                '+852',
                '+853'
            ],
            'width': 75,
            'gap': 5,
            'pattern': ''
        
        }, options );

        var $el = $(el),
            existingValue = el.value,
            $container = $('<div />', {
                'class': options.containerClass
            }),
            $select = $('<select />'),
            $input = $el.clone();

        if ($el.data('isfake')) {
            return false;
        } else {
            if ($el.data('container')) {
                $el.data('container').remove();
            }
        }

        for (i in options.prefix) {
            var selected = el.value.trim().indexOf(options.prefix[i]) === 0;
            if (selected && existingValue) {
                existingValue = existingValue.split(options.prefix[i])[1];
            }
            $select.append(
                $('<option />', {
                    'value': options.prefix[i],
                    'text': options.prefix[i],
                    'selected': selected
                })
            );
        }

        // default styles + attributes
        $select.css({
            'width': options.width
        });
        $input
        .attr('name', el.name + '_fake')
        .data('isfake', true)
        .removeAttr('id')
        .removeAttr('minlength')
        .removeAttr('maxlength')
        .attr('pattern', options.pattern)
        .val(existingValue).css({
            'padding-left': (options.width + options.gap)
        }).show();

        // set phone input and trigger
        $input.add($select)
            .data({ 'pattern': options.pattern })
            .on('change keyup focusout', function(){
            $el.val( $select.val() + $input.val() )
                .trigger('keyup')
                .trigger('focusout')
                .trigger('change');
        });

        // trigger on submit form
        $(document).on('submit.prefix', $el.closest('form'), function(){
            $('.' + options.containerClass + ' input').trigger('change');
        });
        $container.append($select, $input);
        $el.data('container', $container)
            .hide()
            .after($container);
    };

    $.fn.smcpPhonePrefix = function (options) {
        return this.each(function () {
            new $.smcpPhonePrefix(this, options);
        });
    };
})(jQuery);