$.fn.customSelect = function(selectorParent){
	var element = $(this);	
	var children_e = element.closest(selectorParent).find('option');
	var selectUl = $('<ul class="customMg">');
	//var opt_selected = element.find('option:selected');
	
	children_e.first().attr('selected', 'selected');
	element.before(selectUl);
	
	var classSelect = $('.customMg');
	var appendList = element.closest(selectorParent).find(classSelect);
	for(var i=0; i < children_e.length; i++){		
		var optionLi = $("<li class='optionLi' >");
		appendList.append(optionLi);
	}
	var option_e = element.closest(element).prev(classSelect).find('li');
	
		children_e.each(function(){			
			var contentOption = $(this).text();
			var optionLi = $(this).closest(element).prev(classSelect).find('li');
			var dataUrl = $(this).attr('data-url');
			if(dataUrl){
				optionLi.eq($(this).index()).attr('data-url', dataUrl);
			}
			optionLi.eq($(this).index()).html('<span>'+contentOption+'</span>');			
		});
		
	var itemActive = $("<a class='itemSelected'></a>");	
	var iconSelect = $("<span class='itemSelect'></span>");	
	//var itemActive;
	itemActive.insertBefore(element.prev('ul'));
	var cloneFirstItem = option_e.first().clone().text();
	var cloneDataUrlForHref = option_e.first().clone().attr('data-url');
	
	itemActive.text(function(){
		return cloneFirstItem;
	});
	
	if(cloneDataUrlForHref){
		itemActive.attr('href', cloneDataUrlForHref);
	}
	
	var classItemActive = $('.itemSelected');
	var classParent = element.closest(selectorParent).find(classItemActive);
	//alert(classParent.attr('class'));
	//classItemActive.append(iconSelect);
	iconSelect.appendTo(classParent);
	
	var classItemSelect = element.closest(selectorParent).find('.itemSelect');
	
	classSelect.hide();
	
	// event click
	classParent.on('click', function(e){
		e.preventDefault();
		
		$(this).toggleClass('active');
		$(this).next('ul').toggle();
		$(this).closest(selectorParent).css('z-index', '1').siblings(selectorParent).css('z-index', '');
		var classItemSelect= $(this).closest(selectorParent).find('.itemSelect');
		classItemSelect.toggleClass('active');
		
	});
	
	option_e.on('click', function(e){
		e.preventDefault();	
		
		var itemDataUrl = $(this).attr('data-url');
		
		//this condition will override the select behavior:
		//instead of visually change the selection, we will open a new tab/window for the us site and close the dropdown
		if(itemDataUrl){			
			window.open(itemDataUrl);
			$(this).closest('ul').hide();
		}
		else{
			itemActive.text($(this).text());
			itemActive.attr('href', '');
			children_e.eq($(this).index()).attr('selected','selected').siblings().removeAttr('selected');
			$(this).toggleClass('selected').siblings().removeClass('selected');
			$(this).hide().siblings().show();
		}
		
		$(this).closest('ul').prev(itemActive).removeClass('active');
		$(this).closest('ul').hide();
		
		var classItemSelect= $(this).closest(selectorParent).find('.itemSelect');
		
		if(classItemSelect.length === 0){
			var iconSelect = $("<span class='itemSelect'></span>");	
			classParent.append(iconSelect);
		}
		else if(classItemSelect.hasClass('active')){
			classItemSelect.removeClass('active');
		}
		
				
		
	});
	option_e.first().addClass('selected').hide();
	element.hide();
}