Maje.account = Maje.account || {};
Maje.account.bindEvents = function(){
	Maje.account.selectedMenu();
	Maje.account.toogleOverview();
	Maje.account.countryInit();

	Maje.account.hijackEnterKeyHitOnRegistrationForm();
	Maje.account.birthdateInit();
	Maje.account.salutationSelect();
	Maje.account.moveNews();
	Maje.account.resize();
	Maje.account.tooltipError();
	Maje.account.hideLoginError();
	Maje.account.hover();
	Maje.account.bindLoginButton();
	Maje.account.accordeonDetailCommande();
	Maje.account.navMyAccount();
	Maje.account.returnNewTab();
	Maje.account.initConciergePageEvents();
	Maje.account.initConciergeRevealBox();
	Maje.account.forgotPasswordEvents();
	Maje.account.editAccountPageEvents();
	Maje.account.registerPageEvents();
};
Maje.account.selectedMenu = function(){
	var dashboard = $('.myAccount .dashboardIntro').html();
	var wishlist = $('.myAccount .wishlistPage').html();
	var perso = $('.myAccount .perso').html();
	var address = $('.myAccount .addressList').html();
	var orders = $('.myAccount .listOrder').html();
	var orderDetail = $('.myAccount .orderDetails').html();
	var refund = $('.myAccount .return').html();
	var refundpartempty = $('.myAccount.returnsrefunds').html();
	var paymentinstrumentlist = $('.myAccount.paymentinstrumentlist').html();
	var paymentWallet = $('.myAccount .payment-wallet').html();
	var returnAndRefundPolicy = $('.myAccount .returnandrefundpolicy').html();
	var changePassword = $('.myAccount .changepw').html();

	if(dashboard != null){
		$(".dashboard").addClass("active");
	}
	else if(wishlist != null){
		$(".wishlist").addClass("active");
	}
	else if(perso != null){
		$(".perso").addClass("active");
	}
	else if(address != null){
		$(".address").addClass("active");
	}
	else if(paymentWallet != null){
		$(".wallet").addClass("active");
	}
	else if(orders != null || orderDetail != null){
		$(".order").addClass("active");
	}
	else if(returnAndRefundPolicy != null){
		$(".returnandrefundpolicy").addClass("active");
	}
	else if(changePassword != null){
		$(".resetpass").addClass("active");
	}
}
Maje.account.toogleOverview = function(){
	var btnHide = $( '.pt_account_dashboard .accountItem h2' );

	btnHide.on('click',function(){
		if($(window).width() < 768){
			var active = $(this).parent().siblings('.active');
			if (active.length){
				active.find('h2').trigger('click');
			}
			$(this).closest('.accountItem').toggleClass('active');
			$(this).nextAll().slideToggle('500');
		}
	});
}

Maje.account.countryInit = function() {
	$(document).on("change", ".addressEdit select[id$='_country']", function(){
		$(window).trigger('address.load.provinces',{country: this});
	});
}

Maje.account.returnNewTab = function() {

	$(".btn-return-newtab").on("click", function(){
		var returnUrl = $(this).data("return-url");
		window.open(returnUrl,'_blank');
	});

};

Maje.account.navMyAccount = function() {

	if($(".wrapper-sub-nav-dropdown-mob").length) {

		$(".btn-sub-nav-dropdown-mob").on("click", function() {
			if(window.innerWidth < 1024){
				$(".wrapper-sub-nav-dropdown-mob").toggleClass("open");
				$(".content-sub-nav-dropdown-mob ul").slideToggle();
			}
		});

	}

	var navMobile = false;

	$(window).on("resize", function() {
		if(window.innerWidth < 1024 && navMobile === false){
			navMobile = true;
		} else if (window.innerWidth >= 1024 && navMobile === true){
			navMobile = false;
			$(".content-sub-nav-dropdown-mob ul").removeAttr("style");
			$(".wrapper-sub-nav-dropdown-mob").removeClass("open");
		}
	}).resize();

	Maje.account.popinReturnOrder();

};

Maje.account.popinReturnOrder = function() {

	$(".btn-return").on("click", function() {

		$(".popin-returnorder").dialog({
			closeText: app.resources.CLOSE,
			draggable: false,
			dialogClass : 'popin-returnorder-dialog',
			height: "auto",
			width: "600px",
			modal: true,
			position: "center"
		});

	});
}

Maje.account.accordeonDetailCommande = function(){

	/* Open Accordeon on Click */
	if($(".wrapper-accordeon").length > 0){

		$(".wrapper-accordeon").each(function() {

			var _this = $(this);

			_this.find(".btn-accordeon").on("click",function() {

				var wrapAccor = $(this).parent(".wrapper-accordeon");

				_this.find(".content-accordeon").slideToggle("fast", function() {
					wrapAccor.toggleClass("accordeon-close");
				});
			});
		});

	}

}

Maje.account.hijackEnterKeyHitOnRegistrationForm = function(){
	var validateButtonSelector = "#RegistrationForm .apply";
	var validateButton = $(validateButtonSelector);
	if(validateButton.length > 0){
		$(document).on("keypress", function(e){

			var code = (e.keyCode ? e.keyCode : e.which);
			if(code == 13)
			{
				e.preventDefault();
				validateButton.trigger("click");
				e.stopPropagation();
			}
		});
	}

};

// Bind an event to the login button when in the popin to have the popin handle error display
// from back treatments.
// We need to pass the form data as if it was submitted the normal way
// so we need to serialize it, and add the button.
// The format parameter allows for special treatment inside
// the templates (checkoutlogin.isml) and pipeline (COCustomer-Start).
// The pipeline is modified so that an empty template is returned on login success
Maje.account.bindLoginButton = function(){
	$(document).on('click', '#popup-login.ui-dialog-content #button2', function(e){
		e.stopPropagation();
		e.preventDefault();

		var button = $(e.target);
		var form = button.closest('form');

		if(form.valid())
		{
			$.ajax({
				url: form.attr('action'),
				data: form.serialize()+'&'+button.attr('name')+'=1&format=ajax',
				type:'POST'
			}).done(function(data, status){
				if($(data).find('#dwfrm_login').length > 0) {
					$('#popup-login').html(data);
					// Reactivate the validator for each reload of the popin
					// for successive failures
					app.validator.init();
				} else {
					location.assign(button.data('redirect'));
				}
			});
		}
	});
}

Maje.account.hijackEnterKeyHitOnRegistrationForm = function(){
	var validateButtonSelector = "#RegistrationForm .apply";
	var validateButton = $(validateButtonSelector);
	if(validateButton.length > 0){
		$(document).on("keypress", function(e){

			var code = (e.keyCode ? e.keyCode : e.which);
			if(code == 13)
			{
				e.preventDefault();
				validateButton.trigger("click");
				e.stopPropagation();
			}
		});
	}
}

//function move block Newsletter
Maje.account.moveNews = function(){
	var el = {
		news : '.showNewsletterSubscription',
		account : '.pt_account',
		footer : '#footer'
	};


	// we move block of newsletter from homepage

		if($(window).width() < 767 && $(el.account).length === 1){
			if ($(el.footer).prev(el.news).length === 0){
				$(el.footer)
				.find(el.news)
				.clone()
				.insertBefore(el.footer);
			}
		}else{
	// we delete block of newsletter from homepage
			$('body')
			.find(el.footer)
			.prev(el.news)
			.remove();
		}
};
Maje.account.resize = function(){
	$(window).resize(function(){
		Maje.account.moveNews();
	});
};
Maje.account.birthdateInit = function(){
	$(document).on('change','.birthdate-select', function(e){
		e.preventDefault();
		var birthdate = $('#birthdate-day-select').attr('value') + "/" + $('#birthdate-month-select').attr('value') + "/" + $('#birthdate-year-select').attr('value');
		if($('#dwfrm_profile_customer_birthday').length>0){
			$('#dwfrm_profile_customer_birthday').val(birthdate);

			// Check for age and auto-check the age validation box
			if(/\d{2}\/\d{2}\/\d{4}/.test(birthdate)){
				try {
					var ageCheck = $("#legalage, input[name$='_legalage']");
					var now = new Date();
					var then = $.datepicker.parseDate( "dd/mm/yy", birthdate ); // Relies on the presence of the jquery DatePicker plugin
					// The difference between now and then is returned in milliseconds, and we don't need them.
					// 31556952 = 365.2425*24*60*60, the approximate average number of seconds in a year (accurate to ~2h over the tested range)
					var age = Math.floor( (now - then)/1000 ) / 31556952;
					ageCheck.attr("checked", (age >= 16));
					ageCheck.closest('form').validate().element(ageCheck);
				} catch(e) {
					// Do nothing, maybe the parseDate of the DatePicker threw an exception because the entered date is impossible, like 31/02/2018
				}
			}
		}
	});
}

Maje.account.salutationSelect = function(){
	$(document).on('change','.salutation-select', function(e){
		e.preventDefault();
		if($("#dwfrm_profile_customer_salutation").length > 0){
			$("#dwfrm_profile_customer_salutation").attr('value',$(this).val());
		}
		$("#dwfrm_profile_customer_salutation-error").addClass("valid");
	});
}

Maje.account.tooltipError = function(){
	var classE = {
		toolError : '.error-form',
		inputEmail : '#loginform .email-input'
	};
	if($(classE.toolError).length > 0){
		$(classE.toolError).css({
			'width' : $(window).width() > 767 ? '160px' : '90%',
			'margin' :$(window).width() > 767 ? '0' : '0 auto 7px',
			'padding' : '10px',
			'text-align' : 'center',
			'line-height' : '16px',
			'background' : '#b30c03',
			'color' : '#fff',
			'text-transform' : 'none',
			'height' : $(window).width() > 767 ? $(classE.toolError).outerHeight(true) : 'auto',
			'position' : $(window).width() > 767 ? 'absolute' : 'static',
			'top' : $(classE.inputEmail).position().top  - ($(classE.toolError).outerHeight(true) /2),
			'left' : $(classE.inputEmail).position().left - ($(classE.toolError).outerWidth() + 5)

		});
	}
	$(window).resize(function(){
		if($(classE.toolError).length > 0){
			$(classE.toolError).css({
				'width' : $(window).width() > 767 ? '160px' : '90%',
				'margin' :$(window).width() > 767 ? '0' : '0 auto 7px',
				'padding' : '10px',
				'text-align' : 'center',
				'line-height' : '16px',
				'background' : '#b30c03',
				'color' : '#fff',
				'text-transform' : 'none',
				'height' : 'auto',
				'position' : $(window).width() > 767 ? 'absolute' : 'static',
				'top' : $(classE.inputEmail).position().top  - ($(classE.toolError).outerHeight(true) /2),
				'left' : $(classE.inputEmail).position().left - ($(classE.toolError).outerWidth() + 5)

			});
		}
	});
};

Maje.account.hideLoginError = function() {
	$('#loginform .login-email input, #loginform .login-password input').focus(function(e) {
		$('#loginform .main-login-error').remove();
	});
}

Maje.account.bindFocusBlurMobile = function() {
	$('.personal.create .input-text,.personal.create .input-text-pw,.personal.edit .input-text,.personal.edit .input-text-pw,.addressEdit .input-text,.addressEdit .input-text-pw').each(function() {
		if($(window).width() < 767 && $(this).val()) {
			$(this).closest('.tableRow:not(.profile-birthday)').find('div:first label:first').hide();
		}
	});

	$(document).on('focus', '.personal.create .input-text,.personal.create .input-text-pw,.personal.edit .input-text,.personal.edit .input-text-pw,.addressEdit .input-text,.addressEdit .input-text-pw', function() {
		if($(window).width() < 768) {
			$(this).closest('.tableRow:not(.profile-birthday)').find('div:first label:first').hide();
		}
	}).on('blur input', '.personal.create .input-text,.personal.create .input-text-pw,.personal.edit .input-text,.personal.edit .input-text-pw,.addressEdit .input-text,.addressEdit .input-text-pw', function() {
		if ($(window).width() > 767) {
			return false;
		}

		var label = $(this).closest('.tableRow:not(.profile-birthday)').find('div:first label:first');
		if(!$(this).val()) {
			label.css('display', 'inline');
		} else {
			label.hide();
		}
	});
};

Maje.account.bindFormSubmit = function () {
	//Minor validation and ajax call
	$(document).on('click', '#EasyRegister .easy-register-apply', function(e){
		var easyRegisterForm = $("#EasyRegister");
		var accountShowLink = $('#accountShowLink').attr('value');
		if(easyRegisterForm.validate().form()){
			e.preventDefault();
			var passwordField = easyRegisterForm.find(".easy-register-password input");
			if(passwordField.val().length < 8){
				passwordField.focus();
			}
			else{
				var requestData = easyRegisterForm.serialize();
				var buttonName = $("#EasyRegister .easy-register-apply").attr('name');
				var buttonValue = $("#EasyRegister .easy-register-apply").attr('value');
				requestData += '&' + buttonName + '=' + buttonValue;
				$(".easy-register-apply").attr('disabled','disabled');
				$.ajax({
					url : easyRegisterForm.attr('action'),
					type : "POST",
					data : requestData,
					success : function(response){
						if(response){
							var accountShowRedirect = $(response).filter('#accountShowRedirect');
							var postLoginRedirect = $(response).find('#postLoginRedirect');
							if(accountShowRedirect.length != 0){
								$(document).find('div.easy-register-bloc').html(response);
							} else if (postLoginRedirect.length != 0 && postLoginRedirect[0].value != null) {
								window.location.replace(postLoginRedirect[0].value);
							} else {
								window.location.replace(accountShowLink);
							}
						}else{
							$(".easy-register-apply").removeAttr("disabled");
						}
					},
					error : function(){
						window.location.replace(accountShowLink);
					}
				});
			}
		}
	});

	//Update login page form actions with the correct one when sending it.
	//This is needed because we use a Continue node on Account-RequireLogin
	//and it doesn't seem to exist a way to pass URLUtils.httpsContinue()
	//into the content slot
	$('form#dwfrm_login').submit(function(e){
		$(this).attr("action", $('input#loginContinueAction').val());
		return true;
	});
}

Maje.account.returnsHelper = function()
{
	var _returns = this;
	var urlReturn = "";
	var urlReturnDisplay = "";
	var msgErrorGeneric = "";
	var msgErrorQty = "";
	var msgErrorReason = "";
	var currentErrorMsg = "";

	this.init = function()
	{
		_returns.Items = new Array();

		var results = $("div.orderItems").eq(0);
		if(results.length > 0)
		{
			urlReturn = $(results).data("updatereturnurl");
			urlReturnDisplay = $(results).data("updatereturndisplayurl");
			msgErrorGeneric = $(results).data("errormessagegeneric");
			msgErrorQty = $(results).data("errormessageqty");
			msgErrorReason = $(results).data("errormessagereason");
		}
	}

	this.isDesktop = function()
	{
		return $(".hideinmobile-block").is(":visible");
	}

	this.isValid = function()
	{
		var nbZeroQties = 0;
		var qtiesAvailable = false;
		var results = this.isDesktop() ? $("div.orderItems > .infoBlock") : $("#table-order-summary .infoBlockMobile");
		if(urlReturn != "" && results.length > 0 && urlReturnDisplay != "")
		{
			for(i=0; i<results.length; i++)
			{
				var infoBlock = $(results[i]);
				var productID = infoBlock.data("productid");
				if(this.isDesktop())
				{
					if(infoBlock.next(".tableRow"))
					{
						var qtyToReturn = infoBlock.next(".tableRow").find("select.ReturnQty");
						if(qtyToReturn && qtyToReturn.length > 0)
						{
							if(qtyToReturn.val() > 0)
								qtiesAvailable = true;
							else if(qtyToReturn.val() <= 0)
								nbZeroQties++;
						}
					}
				}
				else
				{
					var qtyToReturn = infoBlock.parent().find("select.ReturnQty");
					if(qtyToReturn && qtyToReturn.length > 0)
					{
						if(qtyToReturn.val() > 0)
							qtiesAvailable = true;
						else if(qtyToReturn.val() <= 0)
							nbZeroQties++;
					}
				}
			}

			if((!qtiesAvailable) || (nbZeroQties == results.length))
			{
				currentErrorMsg = msgErrorQty;
				return false;
			}
		}

		var tmp_refundReason = this.isDesktop() ? $(".refundReason").val() : $(".refundReasonMobile").val();
		if(tmp_refundReason != "-10")
			return true;
		else if(tmp_refundReason == "-10")
			currentErrorMsg = msgErrorReason;
		else
			currentErrorMsg = msgErrorGeneric;

		return false;
	}

	this.displayErrorMessage = function()
	{
		$(".returnErrorMsg").html(currentErrorMsg);
	}

	this.extract = function()
	{
		var results = this.isDesktop() ? $("div.orderItems > .infoBlock") : $("#table-order-summary .infoBlockMobile");
		if(results.length > 0)
		{
			for(i=0; i<results.length; i++)
			{
				var infoBlock = $(results[i]);
				var productID = infoBlock.data("productid");
				if(this.isDesktop())
				{
					if(infoBlock.next(".tableRow"))
					{
						var qtyToReturn = infoBlock.next(".tableRow").find("select.ReturnQty");
						if(qtyToReturn && qtyToReturn.length > 0)
						{
							var obj = {};
							obj.productID = productID;
							obj.qtyToReturn = qtyToReturn.val();
							if(obj.qtyToReturn > 0)
								_returns.Items.push(obj);
						}
					}
				}
				else
				{
					var qtyToReturn = infoBlock.parent().find("select.ReturnQty");
					if(qtyToReturn && qtyToReturn.length > 0)
					{
						var obj = {};
						obj.productID = productID;
						obj.qtyToReturn = qtyToReturn.val();
						if(obj.qtyToReturn > 0)
							_returns.Items.push(obj);
					}
				}
			}
		}
	}

	this.update = function()
	{
		if(_returns.Items.length > 0)
		{
			var root = this;
			$.ajax({
				type : "POST",
				url: urlReturn,
				data: this.returnData(),
				datatype: "json"
			})
			.success(function(data, status, jqXHR) {
				if(data && data.Result == "OK")
					root.redirect(data.ID);
				else
					root.displayErrorMessage();
			})
			.error(function(e) {
				root.displayErrorMessage();
			});
		}
	}

	this.returnData = function()
	{
		var data = {};
		data.reason = this.isDesktop() ? $(".refundReason").val() : $(".refundReasonMobile").val();

		for(i=0; i<_returns.Items.length; i++)
		{
			data[_returns.Items[i].productID] = _returns.Items[i].qtyToReturn;
		}

		return data;
	}

	this.redirect = function(id)
	{
		if(id != null && id != "")
		{
			location.href = urlReturnDisplay + "&returnid=" + id;
		}
		else
			location.reload();
	}

	this.init();
	return _returns;
};

// function add maskLayer when hover My account in header
Maje.account.hover = function(){

	if(app.frontUtils.isTouchScreen){

		var menuTouchOpen = false
		$(".loginUserMaje").on("touchstart", function(e) {
			if(menuTouchOpen === false){
				e.preventDefault();
				menuTouchOpen = true;
			}
		});

		$(document).on("touchstart", function(e){
			var condition = $(e.target).parents(".loginUserMaje").length;

			if(!condition){
				menuTouchOpen = false
			}
		});

		$(document).on("touchstart", function(e){
			var condition = $(e.target).parents(".maskOnHover").length;

			if(!condition){
				if ($('#maskLayer').length) {
					$('#maskLayer').remove();
				}
			} else{
				if (!$('#maskLayer').length) {
					$('body').append( '<div id="maskLayer"></div>' );
				}
			}
		});
	} else {
		$('.userInfoHeader li.maskOnHover').on("mouseenter", function(){
			if (!$('#maskLayer').length) {
				$('body').append( '<div id="maskLayer"></div>' );
			}
		}).on("mouseleave", function() {
			if ($('#maskLayer').length) {
				$('#maskLayer').remove();
			}
		});
	}
}

Maje.account.initConciergePageEvents = function() {
	jQuery(document).ready(function () {
		if($(".Conciergerie") != null){
			var anchor = $(".Conciergerie").find('#conciergerie-anchor').val();
			var anchorElement = document.getElementById(anchor);
			if(anchorElement != null) anchorElement.scrollIntoView();
		}
	});
};


function getPercentageVsible(element){
  var eTop = element.offset().top;
  var eBottom = eTop + element.height();
  var wTop = $(window).scrollTop();
  var wBottom = wTop + $(window).height();
  var totalH = Math.max(eBottom, wBottom) - Math.min(eTop, wTop);
  var wComp = totalH - $(window).height();
  var eIn = element.height() - wComp;
  return eIn <= 0 ? 0 : eIn / element.height() * 100;
}


function revealBox(){
	$(".Conciergerie-item").each(function() {
	  var percent = getPercentageVsible($(this));
	  if(percent > 55) {
		  $(this).removeClass('not-visible')
	  }
    });
}

Maje.account.initConciergeRevealBox = function() {
	jQuery(document).ready(function () {
		revealBox();
		$(window).scroll(function() {
			revealBox();
		});
	});
};

Maje.account.registerPageEvents = function() {
	if ( $('.tableRow.verifybtn').length < 1 || $('.accountItem').length > 0)
		return;
	
	$(document).off("click",".get-code").on("click",".get-code", function() {
		$(this).attr("disabled","disabled");
		var timeoutTime = app.constants.RESEND_SMS_INTERVAL * 1000;
		$(this).css("opacity", 0.5);
		var that = this;
		setTimeout(function() {
			$(that).removeAttr("disabled");
			$(that).css("opacity", 1);
		}, timeoutTime);

		$(".get-code-message").empty();
		var phoneNumber = $("input[name='dwfrm_profile_customer_phone']").val();
		$(window).trigger('register.triggercaptcha',[{'form-type': pageContext.type,'url': app.urls.getVerificationCode,'phone' : phoneNumber,'reload': 0}, function(data) {
			if(data.status == "ok") {
				$(".get-code-message").text(app.resources.GetVerificationCodeOK);
			} else {
				if (data.status != "duplicated") {
					$(".get-code-message").text(app.resources.GetVerificationCodeFailed);
				}
			}
		}]);
	})
}
Maje.account.forgotPasswordEvents = function() {
	var flagSubmit = false;
	if (!flagSubmit) {
		$(document).on("click","#submitResetPassword", function(e) {
			e.preventDefault();
			$(window).trigger('forgotpassword.triggercaptcha',[{'form-type': 'forgotpassword','reload': 1}, function(data) {
				for(var i = 0; i < data.length; i++) {
					$("#PasswordResetForm").append(data[i]);
				}
				flagSubmit = true;
				$("#PasswordResetForm").submit();
			}]);
		});
	}


}

Maje.account.editAccountPageEvents = function() {
	$(document).on("keyup","input[name$='profile_customer_phone_fake']" , function() {
		$(".verifybtn").removeClass("hidden");
		$(".verificationcode").removeClass("hidden");
	});

	$(document).on("change",".phone-container select" , function() {
		$(".verifybtn").removeClass("hidden");
		$(".verificationcode").removeClass("hidden");
	});

	$(document).off("click",".get-code").on("click",".get-code", function() {
		$(this).attr("disabled","disabled");
		var timeoutTime = app.constants.RESEND_SMS_INTERVAL * 1000;
		$(this).css("opacity", 0.5);
		var that = this;
		setTimeout(function() {
			$(that).removeAttr("disabled");
			$(that).css("opacity", 1);
		}, timeoutTime);

		$(".get-code-message").empty();
		var phoneNumber = $("input[name='dwfrm_profile_customer_phone']").val();
		var data = {'form-type': pageContext.type, 'phone' : phoneNumber};
		$.ajax({
            url: app.urls.getVerificationCode,
            data: data,
            type: 'POST',
            success: function(result) {
            	if(result.status == "ok") {
    				$(".get-code-message").text(app.resources.GetVerificationCodeOK);
    			} else {
    				if (data.status != "duplicated") {
    					$(".get-code-message").text(app.resources.GetVerificationCodeFailed);
    				}

    			}
            },
            error: function(jqXHR, textStatus, errorThrown) {
            	console.log(errorThrown);
            }
        });
    });
}
